import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Grid, Button, Typography, Box } from '@mui/material';
import './HomePage.css';
import "swiper/css";
import "swiper/css/effect-cards";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Pagination, EffectCards } from 'swiper/modules';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import { useState } from 'react';
import { SocialIcon } from 'react-social-icons';
import useMediaQuery from '@mui/material/useMediaQuery';
import BulletText from './BulletText';
import FableModal from './FableModal';
const fableScreen = require('./images/fable_logo.png');
const swoosh = require('./images/swoosh.png');

const display1 = require('./images/display1.png');
const display2 = require('./images/display2.png');
const display3 = require('./images/display3.png');
const display4 = require('./images/display4.png');
const display5 = require('./images/display5.png');

const story1 = require('./images/oliver.jpg');
const story2 = require('./images/noah.jpg');
const story3 = require('./images/sophia.jpg');
const story4 = require('./images/ethan.jpg');
const story5 = require('./images/erika.jpg');
const story6 = require('./images/liam.jpg');
const story7 = require('./images/emma.jpg');

const nbc = require('./images/nbc.png');
const abc = require('./images/abc.png');
const fox = require('./images/fox.png');
const cbs = require('./images/cbs.png');

const parent_1 = require('./images/parent_1.png');
const parent_2 = require('./images/parent_2.png');
const parent_3 = require('./images/parent_3.png');

const characterImage = require('./images/pro_image.png');
const slideImages = [display1, display2, display3, display4, display5];
const stories = [
    {
        imageUrl: story3,
        title: "Sophia and Her Magical Marble Adventure",
        storyLink: "https://thefableapp.com/story?storyId=68b679b5-80c1-446d-8cbf-2a4dd3f6c69f&userId=d46046ba-8cb0-45be-b338-5f6f98b6a223&chapterIds="
    },
    {
        imageUrl: story2,
        title: "Noah's Starry Ride with Comet Charlie",
        storyLink: "https://thefableapp.com/story?storyId=a2ac1ff8-c32c-4882-ae58-052a56cdd03d&userId=d46046ba-8cb0-45be-b338-5f6f98b6a223&chapterIds="
    }
    ,
    {
        imageUrl: story6,
        title: "Liam's Magical Jungle Adventure",
        storyLink: "https://thefableapp.com/story?storyId=206dd9e5-5b9d-4865-b3e4-b621435ef5dc&userId=d46046ba-8cb0-45be-b338-5f6f98b6a223&chapterIds="
    },
    {
        imageUrl: story1,
        title: "Oliver the Magical Golden Retriever",
        storyLink: "https://thefableapp.com/story?storyId=8aafeb49-7be8-4349-971a-38b3a618e3ab&userId=d46046ba-8cb0-45be-b338-5f6f98b6a223&chapterIds="
    },
    {
        imageUrl: story5,
        title: "Erika's Exciting Christmas Party",
        storyLink: "https://thefableapp.com/story?storyId=61697c6d-4f72-4721-99a0-4a8a758359f8&userId=d46046ba-8cb0-45be-b338-5f6f98b6a223&chapterIds="
    },
    {
        imageUrl: story4,
        title: "Ethan and the Moonlit River",
        storyLink: "https://thefableapp.com/story?storyId=957fcfb7-7881-43fe-95fb-3751905a8574&userId=d46046ba-8cb0-45be-b338-5f6f98b6a223&chapterIds="
    },
    {
        imageUrl: story7,
        title: "Emma and the Magical Paintbrush",
        storyLink: "https://thefableapp.com/story?storyId=d688b778-2ff6-4108-b89c-efc4fd5973fe&userId=d46046ba-8cb0-45be-b338-5f6f98b6a223&chapterIds="
    },
];

const newsImages = [nbc, abc, fox, cbs];
const madeByParents = [{
    image: parent_1,
    title: "Create engaging tales",
    description: "Vivid graphics, narration, and custom characters make every Fable story magical"
}, {
    image: parent_2,
    title: "Teach lessons that matter",
    description: "Turn life lessons and important themes into stories that help your child learn and grow"
}, {
    image: parent_3,
    title: "Spend less on books",
    description: "With Fable, enjoy infinite stories and chapters, saving money and space on books"
}];

const HomePage: React.FC = () => {
  const swipeRef = useRef<any>(null)
  const [swiperEffect, setSwiperEffect] = useState('coverflow');
  const [slideText, setSlideText] = useState("Enjoy the immersive visuals and narration of your stories, with the option to add more chapters")
  const matchesMd = useMediaQuery('(min-width:1200px)');
  const [activeIndex, setActiveIndex] = useState(0);
  const [direction, setDirection] = useState(1);
  const [isPaused, setIsPaused] = useState(false);
  const observerRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setSwiperEffect(matchesMd ? 'coverflow' : 'cards');
  }, [matchesMd]);

    const slides = useMemo(() => (
        <>
            {slideImages.map((image, index) => (
                <SwiperSlide key={index}>
                    <Box className="screenShotContainer">
                        <img
                            style={{ alignSelf: "center" }}
                            className="screenShot"
                            src={image}
                            alt={`Fable Home Page ${index + 1}`}
                        />
                    </Box>
                </SwiperSlide>
            ))}
        </>
    ), []);

    const onStoryPress = useCallback((story: any) => {
        window.location.href = story.storyLink;
    },[])

    

    const storySlides = useMemo(() => (
        <>
            {stories.map((story, index) => (
                <SwiperSlide key={index}>
                    <Box 
                        className="secondaryScreenShotContainer" 
                        onClick={() => onStoryPress(story)}
                        sx={{
                            cursor: 'pointer',
                            transition: 'transform 0.3s ease',
                            boxShadow: '0 8px 15px rgba(0, 0, 0, 0.2)',
                            '&:hover': {
                                transform: 'scale(1.1)',
                                boxShadow: '0 8px 15px rgba(0, 0, 0, 0.6)'
                            },
                        }}
                        >
                        <img
                            className={"secondaryScreen card-image"}
                            src={story.imageUrl}
                            alt={`Fable Home Page ${index + 1}`}
                        />
                        <Typography className={'story'}>{story.title}</Typography>
                    </Box>
                </SwiperSlide>
            ))}
        </>
    ), [onStoryPress]);

    const newsSlides = useMemo(() => (
        <Box className="tertiaryScreenShotContainer">
            <Grid container spacing={2} justifyContent="center">
                {newsImages.map((image, index) => (
                    <Grid item xs={3} sm={3} md={3} key={index} className="tertiaryScreenShotContainer">
                        <img
                            className="tertiaryScreen news-card-image"
                            src={image}
                            alt={`Fable Home Page ${index + 1}`}
                        />
                    </Grid>
                ))}
            </Grid>
        </Box>
  
    ), []);

    const madeByParentsSlides = useMemo(() => (
        <Box className="parentContainer">
            <Grid container spacing={2} justifyContent="center">
                {madeByParents.map((parent, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index} className="parentBox">
                        <img
                            className="parentImage"
                            src={parent.image}
                            alt={`Fable Home Page ${index + 1}`}
                        />
                        <Typography className="parentTitle">{parent.title}</Typography>
                        <Typography className="parentDescription">{parent.description}</Typography>
                    </Grid>
                ))}
            </Grid>
        </Box>
    ), []);

    const onSlideChangeHandler = useCallback((swiper: { activeIndex: React.SetStateAction<number>; }) => {
        setActiveIndex(swiper.activeIndex);
        if (swiper.activeIndex === 0) {
            setSlideText("Enter a prompt, add characters, and bring your story to life");
        } else if (swiper.activeIndex === 1) {
            setSlideText("Set the age of the reader, visual style, and narrator voice to personalize your story");
        } else if (swiper.activeIndex === 2) {
            setSlideText("Enjoy the immersive visuals and narration of your stories, with the option to add more chapters");
        } else if (swiper.activeIndex === 3) {
            setSlideText("Create the perfect characters for your stories. Upgrade to Pro to personalize characters using photos");
        } else {
            setSlideText("Save and revisit your stories anytime, ready to enjoy or share");
        }
    },[]);

    //This will start auto scroll when slider becomes visible
    useEffect(() => {
        // Observer to track visibility of the slider
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsVisible(entry.isIntersecting);
            },
            { threshold: 0.5 } // Adjust threshold as needed
        );

        if (observerRef.current) {
            observer.observe(observerRef.current);
        }

        return () => {
            if (observerRef.current) {
                observer.unobserve(observerRef.current);
            }
        };
    }, []);

    useEffect(() => {
        if (!isVisible || isPaused) return;
    
        let intervalDuration = 3000; // Default interval duration
        const adjustInterval = () => {
            if (swipeRef?.current) {
                const swiper = swipeRef.current.swiper;
                if ((swiper.isEnd && direction === 1) || (swiper.isBeginning && direction === -1)) {
                    return 1250; // Shorter duration for first and last index
                }
            }
            return 2500; // Default duration
        };
    
        const startSliding = () => {
            if (swipeRef?.current) {
                const swiper = swipeRef.current.swiper;
                const isEnd = swiper.isEnd && direction === 1;
                const isBeginning = swiper.isBeginning && direction === -1;
    
                if (isEnd) {
                    setDirection(-1); // Reverse direction to backward
                } else if (isBeginning) {
                    setDirection(1); // Reverse direction to forward
                }
    
                swiper.slideTo(swiper.activeIndex + direction);
            }
        };
    
        const intervalHandler = () => {
            startSliding();
            intervalDuration = adjustInterval(); // Adjust interval based on current state
            clearInterval(interval); // Reset the interval with the new duration
            interval = setInterval(intervalHandler, intervalDuration);
        };
    
        let interval = setInterval(intervalHandler, intervalDuration);
    
        return () => clearInterval(interval); // Cleanup interval on unmount
    }, [direction, isPaused, isVisible]);

    const handleTouchStart = useCallback(() => setIsPaused(true),[]);

  const socialSizes = swiperEffect === "cards" ? {height: 60, width: 60} : {height: 80, width: 80}

  return ( 
    <Box className="container" flexDirection={"column"}>
        <Grid container spacing={4} className="content">
            <Grid item xs={12} md={12} lg={6} className="textContainer">
                <Typography variant="h2" className="title">
                    Create narrated short stories in seconds with Fable
                </Typography>
                <Typography variant="body1" className="description">
                    Fable is your go-to app for creating short stories with personalized imagery. Try for free today to see the magic of Fable!
                </Typography>
                <Grid container direction="row" spacing={2} className="buttonContainer">
                    <Grid item xs={12} md={12} lg={6}>
                        <a href="https://apps.apple.com/us/app/fable-app/id6497625686">
                            <Button variant="contained" className="iosButton">
                                Try for free on IOS
                            </Button>
                        </a>
                    </Grid>
                    <Grid item xs={12} md={12} lg={6}>
                        <a href="https://play.google.com/store/apps/details?id=com.fableapp">
                            <Button variant="outlined" className="androidButton">
                                Try for free on Android
                            </Button>
                        </a>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={12} lg={6} className="imageContainer">
                <img
                    src={fableScreen}
                    alt="Narrated short story illustration"
                    className="image"
                />
            </Grid>
        </Grid>
        <Grid item xs={12} className="imageContainer">
            <img
            src={swoosh}
            alt=""
            className="swoosh"
            />
        </Grid>

        {/* MAde By Parents Slides */}
        <Grid container spacing={4} className="content">
            <Grid item xs={12} className={"textContainer textContainerRelative"}>
                <Typography variant="h2" className={"title centered"}>
                    Made by Parents, for Parents – Simplify Story Time Today with Fable
                </Typography>
                <Typography variant="body1" className={"description centered"}>
                    Fable helps parents make story time meaningful and stress-free. Focus on what matters most—creating lasting memories with your kids 
                </Typography>
                <Grid item xs={12} sm="auto" className="iconsContainer">
                    {madeByParentsSlides}
                </Grid>
            </Grid>
        </Grid>

        {/* Tutorial Slides */}
        <Grid container spacing={4} className="content section" >
            <Grid item xs={12} className={"textContainer"}>
                <Typography variant="h2" className={"title centered"}>
                    How Fable works
                </Typography>
                <Typography variant="body1" className={"description centered"}>
                    Fable is your go to app for creating short stories with personalized imagery. Try  for free today to see the magic of Fable! 
                </Typography>
                <Grid ref={observerRef} item xs={12} sm="auto" className="slideContainer smaller-section">
                    <Swiper
                        ref={swipeRef}
                        effect={swiperEffect}
                        grabCursor={true}
                        modules={[EffectCards, EffectCoverflow, Pagination]}
                        coverflowEffect={swiperEffect === 'coverflow' ? {
                            rotate: 0,
                            stretch: 0,
                            depth: 200,
                            modifier: 2,
                            slideShadows: true,
                        } : undefined}
                        initialSlide={activeIndex}
                        onSlideChange={onSlideChangeHandler}
                        centeredSlides={true}
                        style={{ overflow: "visible", maxWidth: "380px"}}
                        key={swiperEffect} 
                        onSliderMove={handleTouchStart}
                    >
                        {slides}
                    </Swiper>
                </Grid>
                <Grid item xs={12} sm="auto" className="slideContainer">
                <Box className={'appDescription'}>
                        {slideText}
                </Box>
                </Grid>
            </Grid>
        </Grid>

        {/* Story Slides */}
        <Grid container spacing={4} className="content">
            <Grid item xs={12} className={"textContainer textContainerRelative"}>
                <Typography variant="h2" className={"title centered"}>
                    See some stories from our customers
                </Typography>
                <Typography variant="body1" className={"description centered"}>
                    Cheeck out some sample stories our customers have created to see the power of Fable 
                </Typography>
                <Grid item xs={12} sm="auto" className="slideContainer">
                    <Swiper
                        effect={swiperEffect}
                        grabCursor={true}
                        modules={[Pagination]}
                        slidesPerView={1}
                        initialSlide={3}
                        centeredSlides={true}
                        spaceBetween={30}
                        style={{ overflow: "visible", maxWidth: "200px",}}
                        key={"stories"} 
                    >
                        {storySlides}
                    </Swiper>
                </Grid>
            </Grid>
        </Grid>

        {/* News Slides */}
        {/* <Grid container spacing={4} className="content section">
            <Grid item xs={12} className={"textContainer textContainerRelative"}>
                <Typography variant="h2" className={"title centered"}>
                    Trusted by parents and publications 
                </Typography>
                <Typography variant="body1" className={"description centered"}>
                    Fable was created by parents, for our children. It’s a safe and nurturing space where kids can explore the magic of storytelling. We prioritize your family’s privacy, which is why we never sell data. 
                </Typography>
                <Grid item xs={12} sm="auto" className="iconsContainer">
                    {newsSlides}
                </Grid>
            </Grid>
        </Grid> */}

        <Grid container spacing={4} className={"content section"}>
            <Grid item xs={12} md={12} lg={6} order={swiperEffect === "cards" ? 2: 1} className="textContainer">
                <Typography variant="h2" className="title">
                    Want your characters to look like you or others? Go 👑  
                    <span className="primary"> Pro</span>
                </Typography>
                <Box className="bullets"> 
                    <BulletText text="3 Photo realistic characters a month" isBold={false} />
                    <BulletText text="50 stories or chapters a month" isBold={false} />
                    <BulletText text="Access to more visual styles and narrators" isBold={false} />
                </Box>
                <Grid container direction="row" spacing={2} className="buttonContainer">
                    <Grid item xs={12} md={12} lg={6} className="pro-buttons">
                        <a href="https://apps.apple.com/us/app/fable-app/id6497625686">
                            <Button variant="contained" className={"iosButton buttonPrimary"}>
                                Dowload on IOS
                            </Button>
                        </a>
                    </Grid>
                    <Grid item xs={12} md={12} lg={6} className="pro-buttons">
                         <a href="https://play.google.com/store/apps/details?id=com.fableapp">
                            <Button variant="outlined" className="androidButton">
                                Download on Android
                            </Button>
                        </a>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={12} lg={6}  className="imageContainer">
                <img
                    src={characterImage}
                    alt="Narrated short story illustration"
                    className={"image no-shadow"}
                />
            </Grid>
        </Grid>

        {/* Social Slides */}
        <Grid container spacing={4} className="content section">
            <Grid item xs={12} className={"textContainer textContainerRelative"}>
                <Typography variant="h2" className={"title centered"}>
                    Follow us! Stay up to date with all the latest feature releases
                </Typography>
                <Typography variant="body1" className={"description centered"}>
                    Stay upto date on Fable and the latest features by following our socials or signing up to our email list! 
                </Typography>
                <Grid item xs={12} sm="auto" className="iconsContainer">

                <Box className="tertiaryScreenShotContainer">
                    <SocialIcon style={socialSizes} href="https://www.instagram.com/reel/C52d0Itrd2H" url="https://www.instagram.com"/>
                </Box>
                <Box className="tertiaryScreenShotContainer">
                    <SocialIcon style={socialSizes} url="https://www.x.com" href="https://x.com/TheFableApp/status/1819418793359430019"/>
                </Box>
                <Box className="tertiaryScreenShotContainer">
                    <SocialIcon  style={socialSizes} url="https://www.linkedin.com" href="https://www.linkedin.com/feed/update/urn:li:activity:7218829300907536384"/>
                </Box>
                </Grid>
            </Grid>
        </Grid>

        <Grid container spacing={4} className="content section">
             <Grid item xs={12} className={"textContainer textContainerRelative"}>
                <Typography variant="h2" className={"title centered"}>
                    Download and try Fable for free!
                </Typography>
                <Typography variant="body1" className={"description centered"}>
                    Give Fable a try and create one free story today!
                </Typography>
                <Grid container direction="row" spacing={2} className="buttonContainer">
                    <Grid item xs={12} md={4} lg={3} className="pro-buttons">
                        <a href="https://apps.apple.com/us/app/fable-app/id6497625686">
                            <Button variant="contained" className={"iosButton"}>
                                Dowload on IOS
                            </Button>
                        </a>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3} className="pro-buttons">
                        <a href="https://play.google.com/store/apps/details?id=com.fableapp">
                            <Button variant="outlined" className="androidButton">
                                Download on Android
                            </Button>
                        </a>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12} className="imageContainer section">
            <img
            src={swoosh}
            alt=""
            className="swoosh"
            />
        </Grid>

        <Box className="link-container">
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <a
                href="https://thefableapp.com/terms-of-service"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: 50,
                  width: 96,
                  textDecoration: 'none',
                }}
                rel="noopener noreferrer"
              >
                <span style={{ fontSize: 18, color: 'white' }}>Terms</span>
              </a>
              <span style={{ fontSize: 24, color: 'white', margin: '0 10px' }}> • </span>
              <a
                href="https://thefableapp.com/privacy"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: 50,
                  width: 96,
                  textDecoration: 'none',
                }}
                rel="noopener noreferrer"
              >
                <span style={{ fontSize: 18, color: 'white' }}>Privacy</span>
              </a>
            </Box>
        </Box>

        <Grid container spacing={4}>
             <Grid item xs={12} className={"textContainer textContainerRelative"}>
                <Typography variant="h2" className={"title centered primary"}>
                    Watch the magic unfold
                </Typography>
                <Typography variant="body1" className={"description centered"}>
                    Fable, 2024
                </Typography>
            </Grid>
        </Grid>

    </Box>
  );
};

export default HomePage;
